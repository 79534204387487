import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/sass/main.scss';

import AgeGate from "../components/age-gate"
import Footer from "../components/footer"

/*Assets*/
import white_logo from "../assets/images/chowie-logo-w.png"

export default () => (
    <div className="d-flex flex-column h-100">
        <div id="thank-you" className="py-5 text-center">
            <img className=" mb-4 mt-5" src={white_logo} alt="Chowie Wowie Logo"/>
            <h2 className="mb-4 ">hurrah!</h2> 
            <p>Email sign up success! Expect an email soon.</p>
        </div>
      <AgeGate/>     
      <Footer />
    </div>
  )